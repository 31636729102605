import React, {useState , useEffect} from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { FaInstagram, FaVimeo, FaFacebook, FaYoutube, FaTwitter, FaLinkedin } from "react-icons/fa";

const Footer = ({showBelow}) => {
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        }else {
            if (show) setShow(false)
        }
    }

    const contact_info = useStaticQuery(
      graphql`
        query{
          directus {
            contacts {
              email
              social_links
            }
          }
        }
      `
    )
    const icons = {
      instagram: <FaInstagram />,
      vimeo: <FaVimeo />,
      linkedin: <FaLinkedin/>,
      twitter: <FaTwitter/>,
      youtube: <FaYoutube/>,
      facebook: <FaFacebook/>

    }
    const email = contact_info.directus.contacts.email
    const social = contact_info.directus.contacts.social_links.map(e => <li><a alt={e.icon + " link"} href={e.url}>{icons[e.icon]}</a></li>)

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    const handleClick = () => {
        window[`scrollTo`]({top: 0, behavior: `smooth`})
    }
    return (
        <footer className="rn-footer-area bg-color-black pt--90 pb--60">
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.1s">
                            <div className="ft-info">
                                <p>AMIRA <br />KOPEYEVA</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.4s">
                            <div className="ft-info">
                                <p><a href={"mailto:" + email}>{email}</a></p>
                            </div>
                        </div>   
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.6s">
                            <div className="ft-info">
                                <ul className="social-share">
                                    {social}
                                </ul>
                            </div>
                        </div>                 
                    </div>
                </div>
            </div>
            <div className="back-to-top">
                <div className="backtop" onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex="0">
                    <span className="top"></span>
                </div>
            </div>
        </footer>
    )
}
export default Footer
